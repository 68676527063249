<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-col cols="12">
			<v-card class="mt-3">
				<v-row class="px-3 px-lg-0 mb-n6">
					<v-col class="d-none d-md-block pl-lg-5 pr-1">
						<v-autocomplete
							:items="fabricantes.lista"
							item-text="fabricante"
							item-value="idfabricante"
							label="Fabricante"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idfabricante"
							@change="listarSecoes(), listarGrupos(), listarSubGrupos()"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="secoes.lista"
							item-text="secao"
							item-value="idsecao"
							label="Seção"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idsecao"
							@change="listarGrupos(), listarSubGrupos()"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="grupos.lista"
							item-text="grupo"
							item-value="idgrupo"
							label="Grupo"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idgrupo"
							@change="listarSubGrupos()"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="subgrupos.lista"
							item-text="subgrupo"
							item-value="idsubgrupo"
							label="SubGrupo"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idsubgrupo"
						></v-autocomplete>
					</v-col>
					<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
						<v-sheet class="text-center" height="100%">
							<v-row class="pa-3">
								<v-col cols="12" class="mb-n8">
									<v-autocomplete
										:items="fabricantes.lista"
										item-text="fabricante"
										item-value="idfabricante"
										label="Fabricante"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idfabricante"
										@change="listarSecoes(), listarGrupos(), listarSubGrupos()"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="secoes.lista"
										item-text="secao"
										item-value="idsecao"
										label="Seção"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idsecao"
										@change="listarGrupos(), listarSubGrupos()"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="grupos.lista"
										item-text="grupo"
										item-value="idgrupo"
										label="Grupo"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idgrupo"
										@change="listarSubGrupos()"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="subgrupos.lista"
										item-text="subgrupo"
										item-value="idsubgrupo"
										label="SubGrupo"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idsubgrupo"
									></v-autocomplete>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-bottom-sheet>
					<v-col class="px-1 pr-lg-5 d-flex">
						<v-text-field
							class="d-flex d-md-none"
							:disabled="carregando"
							v-model="busca.busca"
							label="Buscar"
							outlined
							dense
							@keydown.enter="listar(0)"
							append-outer-icon="mdi-menu"
							@click:append-outer="sheet = !sheet"
						/>
						<v-text-field
							class="d-none d-md-block"
							:disabled="carregando"
							v-model="busca.busca"
							label="Buscar"
							outlined
							dense
							@keydown.enter="listar(0)"
						/>
						<v-btn
							:disabled="carregando"
							class="ml-2 mt-1"
							color="primary"
							elevation="0"
							fab
							x-small
							@click="listar(0)"
						>
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card dark flat rounded="0" color="primary" class>
					<v-card-text>
						<v-row>
							<v-col
								cols="12"
								class="mb-n3 font-weight-bold text-body-2 white--text"
							>Os valores alterados nesta tela deverão ser conferidos na tela de MANUTENÇÃO DE PREÇOS POR PRODUTO ou no CISS, pois os itens listados na tela atual refletem os valores dos produtos na empresa 13.</v-col>
							<v-col class="pr-1">
								<v-autocomplete
									:items="filiaisFiltro"
									class="mb-n6"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col class="px-1">
								<v-text-field
									outlined
									class="mb-n6"
									label="Margem de Lucro Geral"
									suffix="%"
									dense
									type="number"
									v-model.number="margemGeral"
									@change="valorVendaGeral()"
								></v-text-field>
							</v-col>
							<v-col cols="auto">
								<v-btn :loading="carregando" color="primary" elevation="0" @click="atualizarValores()">
									<v-icon class="pr-1">mdi-content-save-outline</v-icon>Salvar
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th class="text-left">ID</th>
								<th class="text-left">Produto</th>
								<th class="text-left">Fabricante</th>
								<th class="text-left">Custo atual</th>
								<th class="text-center">Margem atual</th>
								<th class="text-left">Valor venda atual</th>
								<th class="text-left">Nova margem</th>
								<th class="text-left">Alterado</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="produtos.length > 0">
								<tr v-for="(u, i) in produtos" :key="i">
									<td class="text-truncate">{{u.idproduto}}</td>
									<td class="text-truncate">{{u.descricao}}</td>
									<td class="text-truncate">{{u.idfabricante}}-{{u.fabricante}}</td>
									<td class="text-truncate">R$ {{u.custoultimacompra.toFixed(2)}}</td>
									<td class="text-truncate text-center">{{u.margem.toFixed(2)}} %</td>
									<td class="text-truncate">R$ {{u.venda.toFixed(2)}}</td>
									<td class="text-truncate text-center" style="max-width: 50px;">
										<v-text-field
											class="mb-n3 text-body-2"
											reverse
											dense
											type="number"
											prefix="%"
											v-model.number="u.margemnova"
										></v-text-field>
									</td>
									<td>
										<v-checkbox readonly dense class="ml-3 mb-n3" :input-value="verificaAlteracao(u)" value></v-checkbox>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="8">Nenhum registro encontrado.</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	components: { CardDialog },
	name: "ComprasManutencaoPrecoFornecedor",
	mixins: [mixinFilial],
	data: () => ({
		sheet: false,
		carregando: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		fabricantes: {},
		secoes: {},
		grupos: {},
		subgrupos: {},
		busca: { idfiliais: [] },
		produtos: [],
		baseProdutos: [],
		margemGeral: null,
		produtosAlterados: [],
	}),
	computed: {
		...mapState(["backendUrl", "usuario", "pgLimit"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				let arr = this.filiais.lista;
				arr.unshift({
					idfilial: 9999,
					filial: "Todas as Filiais",
				});
				return arr.filter((v) => v.idfilial != 6 && v.idfilial != 33);
			} else {
				return [];
			}
		},
	},
	methods: {
		listarFabricantes() {
			this.busca.idfabricante = null;
			this.fabricantes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/fabricante/listar`)
				.then((res) => {
					this.fabricantes = res.data;
					this.fabricantes.lista = this.fabricantes.lista.map((v) => {
						return {
							idfabricante: v.idfabricante,
							fabricante: `${v.idfabricante} - ${v.fabricante}`,
						};
					});
				});
		},
		listarSecoes() {
			this.busca.idsecao = null;
			this.secoes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/secao/listar`, {
					idfabricante: this.busca.idfabricante || null,
				})
				.then((res) => {
					this.secoes = res.data;
					this.secoes.lista = this.secoes.lista.map((v) => {
						return {
							idsecao: v.idsecao,
							secao: `${v.idsecao} - ${v.secao}`,
						};
					});
				});
		},
		listarGrupos() {
			this.busca.idgrupo = null;
			this.grupos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/grupo/listar`, {
					idfabricante: this.busca.idfabricante || null,
					idsecao: this.busca.idsecao || null,
				})
				.then((res) => {
					this.grupos = res.data;
					this.grupos.lista = this.grupos.lista.map((v) => {
						return {
							idgrupo: v.idgrupo,
							grupo: `${v.idgrupo} - ${v.grupo}`,
						};
					});
				});
		},
		listarSubGrupos() {
			this.busca.idsubgrupo = null;
			this.subgrupos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/subgrupo/listar`, {
					idfabricante: this.busca.idfabricante || null,
					idsecao: this.busca.idsecao || null,
					idgrupo: this.busca.idgrupo || null,
				})
				.then((res) => {
					this.subgrupos = res.data;
					this.subgrupos.lista = this.subgrupos.lista.map((v) => {
						return {
							idsubgrupo: v.idsubgrupo,
							subgrupo: `${v.idsubgrupo} - ${v.subgrupo}`,
						};
					});
				});
		},
		listar() {
            console.log(this.busca)
			if ((!this.busca.idfabricante || this.busca.idfabricante == "") &&
                (!this.busca.idsecao || this.busca.idsecao == "") &&
                (!this.busca.idgrupo || this.busca.idgrupo == "") &&
                (!this.busca.idsubgrupo || this.busca.idsubgrupo == "") &&
                (!this.busca.idsubgrupo || this.busca.idsubgrupo == "")
            ) {
                this.dialogErro.title = "Aviso";
                this.dialogErro.msg =
                    "É necessário informar ao menos um filtro para a busca!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                return;
            }
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}compra/produtofornecedor/listar`, {
                        limit: 99999999,
                        offset: 0,
                        idfabricante: this.busca.idfabricante || null,
                        idsecao: this.busca.idsecao || null,
                        idgrupo: this.busca.idgrupo || null,
                        idsubgrupo: this.busca.idsubgrupo || null,
                        busca: this.busca.busca || null,
                    })
                    .then((res) => {
                        this.margemGeral = null;
                        this.carregando = false;
                        this.produtos = res.data.lista
                            .filter((v) => v.custoultimacompra > 0)
                            .map((v) => {
                                return {
                                    ...v,
                                    margemnova: v.margem,
                                };
                            });
                        this.baseProdutos = res.data.lista
                            .filter((v) => v.custoultimacompra > 0)
                            .map((v) => {
                                return {
                                    ...v,
                                    margemnova: v.margem,
                                };
                            });
                        localStorage.setItem(
                            "filtros_preco_fornecedor",
                            JSON.stringify(this.busca)
                        );
                        localStorage.setItem(
                            "produtos_preco_fornecedor",
                            JSON.stringify(this.produtos)
                        );
                        localStorage.setItem(
                            "base_preco_fornecedor",
                            JSON.stringify(this.produtos)
                        );
                    })
                    .catch(() => {
                        this.carregando = false;
                        this.dialogErro.title = "Erro";
                        this.dialogErro.msg =
                            "Não foi possível listar os produtos com o filtro informado!";
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    });
		},
		valorVendaGeral() {
			if (this.margemGeral) {
				this.produtos.forEach((p) => (p.margemnova = this.margemGeral));
			}
		},
		verificaAlteracao(produto) {
			let margemBase = this.baseProdutos.filter(
				(p) =>
					p.idproduto == produto.idproduto &&
					p.idfabricante == produto.idfabricante &&
					p.descricao == produto.descricao
			)[0].margem;
			if (margemBase == produto.margemnova) {
				return false;
			} else {
				return true;
			}
		},
		atualizarValores() {
			this.produtosAlterados = [];
			this.produtos.forEach((p) => {
				this.baseProdutos.forEach((pa) => {
					if (
						p.idproduto == pa.idproduto &&
						p.idfabricante == pa.idfabricante &&
						p.descricao == pa.descricao &&
						p.margemnova != pa.margem
					) {
						let produto = {};
						produto.idsubproduto = p.idproduto;
						produto.margem = p.margemnova;
						this.produtosAlterados.push(produto);
					}
				});
			});
			if (this.produtosAlterados.length == 0) {
				this.dialogErro.title = "Aviso";
				this.dialogErro.msg = "Nenhuma margem foi alterada!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.busca.idfiliais.length == 0) {
				this.dialogErro.title = "Aviso";
				this.dialogErro.msg = "Nenhuma filial foi selecionada!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			let filiais = [];
			if (this.busca.idfiliais.filter((v) => v == 9999).length > 0) {
				filiais = this.filiaisFiltro
					.filter((v) => v.idfilial != 9999)
					.map((v) => v.idfilial);
			} else {
				filiais = this.busca.idfiliais;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/produtofornecedor/atualizar`, {
					filiais: filiais || null,
					produtos: this.produtosAlterados,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.dialog = false;
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg =
							"Produto(s) alterado(s) com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listar(0);
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg = "Erro ao atualizar produto(s)!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Erro ao atualizar produto(s)!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			await this.listarFabricantes();
			await this.listarSecoes();
			await this.listarGrupos();
			await this.listarSubGrupos();
			const json = localStorage.getItem("filtros_preco_fornecedor");
			const filtro = JSON.parse(json);
			if (filtro) {
				this.busca = filtro;
			}
			const jsonProdutos = localStorage.getItem(
				"produtos_preco_fornecedor"
			);
			const produtos = JSON.parse(jsonProdutos);
			if (produtos) {
				this.produtos = produtos;
				console.log(this.produtos);
			}
			const jsonBase = localStorage.getItem("base_preco_fornecedor");
			const baseProdutos = JSON.parse(jsonBase);
			if (baseProdutos) {
				this.baseProdutos = baseProdutos;
			}
		},
	},
	watch: {
		produtos: {
			handler: function () {
				localStorage.setItem(
					"produtos_preco_fornecedor",
					JSON.stringify(this.produtos)
				);
			},
			deep: true,
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>